import "@lottiefiles/lottie-player";

const logoFooter = document.getElementById('logoLottieFooterWrapper')
const logoFooterAnim = document.getElementById('logoLottieFooter')

logoFooter.addEventListener("mouseover", () => {
	logoFooterAnim.setDirection(1);
	logoFooterAnim.play();
	//console.log('hover')
});

logoFooter.addEventListener("mouseleave", () => {
	logoFooterAnim.setDirection(-1);
	logoFooterAnim.play();
	//console.log('leave')
});

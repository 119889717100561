import Splide from '@splidejs/splide';

// init carousels
Splide.defaults = {
	type   : 'slide',
	arrows: true,
	pagination: false,
	autoWidth: true,
	type: 'loop',
	speed: 800,
	easing: 'cubic-bezier(.12,.96,.51,.99)'
}

const carouselElements = document.querySelectorAll(".splide");


// change client / collabs
carouselElements.forEach((element,index) => {

	const splideCarousel = new Splide(element).mount();

	const client = element.querySelectorAll('.client');
	const collabs = element.querySelectorAll('.collabs');
	//-const counter = element.querySelectorAll('.count span');
	const slides = element.querySelectorAll('.splide__slide');

	splideCarousel.on( 'move', function (newIndex, prevIndex, destIndex) {

		//client[0].classList.add("animating");
		//collabs[0].classList.add("animating");
		//-counter[0].classList.add("animating");
		//floater.classList.remove("show");

		slides[prevIndex].classList.remove("showing");
		//console.log('prev:'+prevIndex)
		slides[destIndex].classList.add("showing");
		//console.log('prev:'+destIndex)
	});

	splideCarousel.on( 'moved', function () {
		let splideSlides = element.querySelectorAll('.splide__slide');

		client[0].innerHTML = splideSlides[splideCarousel.index].dataset.client
		collabs[0].innerHTML = splideSlides[splideCarousel.index].dataset.collabs
		//-counter[0].innerHTML = '0'+(splideCarousel.index + 1)

		floaterClient.innerHTML = slides[(splideCarousel.index + 1)].dataset.clientonly
		//floater.classList.add("show");

		//client[0].classList.remove("animating");
		//collabs[0].classList.remove("animating");
		//-counter[0].classList.remove("animating");

		if (splideCarousel.index == (splideCarousel.length - 1)) {
			client[0].classList.add("shifted");
			collabs[0].classList.add("shifted");
		} else {
			client[0].classList.remove("shifted");
			collabs[0].classList.remove("shifted");
		}

	});
});


// floater
const floaterTriggers = document.querySelectorAll(".splide__arrow");
const floater = document.getElementById('floater')
const floaterDirection = document.getElementById('floaterDirection');
const floaterClient= document.getElementById('floaterClient');

floaterTriggers.forEach((element,index) => {

	element.addEventListener("mouseenter", (event) => {

		let parentCarousel = element.closest('.splide')
		let prevSlide = parentCarousel.querySelectorAll('.is-prev')
		let nextSlide = parentCarousel.querySelectorAll('.is-next')

		//console.log(nextSlide)

		if (element.classList.contains('splide__arrow--prev')) {

			if (prevSlide[0]) {
				floaterDirection.innerHTML = "Prev"
				floaterClient.innerHTML = prevSlide[0].dataset.clientonly
				floater.classList.add('show')
			}
		} else {
			if (nextSlide[0]) {
				floaterDirection.innerHTML = "Next"
				floaterClient.innerHTML = nextSlide[0].dataset.clientonly
				floater.classList.add('show')
			}
		}

	});

	element.addEventListener("mouseleave", (event) => {
		floater.classList.remove('show')
	});

});

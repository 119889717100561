import "@lottiefiles/lottie-player";
import { checkVisible } from "../../js/check-visible";

const topicsLotties = document.querySelectorAll(".topics-lottie")

topicsLotties.forEach((element) => {
	window.addEventListener('scroll', function() {
			if (checkVisible(element)) {
				setTimeout(() => {
					element.play()
				}, 500);
			} else {
				// for replay
				//element.stop()
				//element.seek(0)
			}
	});
})

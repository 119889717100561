import "@lottiefiles/lottie-player";
import { checkVisible } from "../../js/check-visible";

const logo = document.getElementById('logoLottieWrapper')
const logoAnim = document.getElementById('logoLottie')
const contact = document.getElementById('contact')
const contactMobileFloating = document.getElementById('contactMobileFloating')
//const darkZone = document.getElementById('dark-zone')

logo.addEventListener("mouseover", () => {
	logoAnim.setDirection(1);
	logoAnim.play();
	//console.log('hover')
});

logo.addEventListener("mouseleave", () => {
	logoAnim.setDirection(-1);
	logoAnim.play();
});

//mobile contact button
document.addEventListener("scroll", (event) => {
	//scroll is over 100
	if ((window.scrollY >= 100) && !checkVisible(contact)) {
		contactMobileFloating.classList.add('show')
	} else {
		contactMobileFloating.classList.remove('show')
	}
	//scrolling over dark zone
	//if (elementsOverlap(contactMobileFloating, darkZone)) {
	//	contactMobileFloating.classList.add('negative')
	//} else {
	//	contactMobileFloating.classList.remove('negative')
	//}
});

// check overlap
function elementsOverlap(el1, el2) {
	const domRect1 = el1.getBoundingClientRect();
	const domRect2 = el2.getBoundingClientRect();

	return !(
	  domRect1.top > domRect2.bottom ||
	  domRect1.right < domRect2.left ||
	  domRect1.bottom < domRect2.top ||
	  domRect1.left > domRect2.right
	);
 }

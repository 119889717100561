//import css
import "./scss/main.scss";

//import components js
import "./components/about/about.js";
import "./components/background/background.js";
import "./components/background/buttonCtrl.js";
import "./components/background/cursor.js";
import "./components/background/utils.js";
import "./components/carousel/carousel.js";
import "./components/contact/contact.js";
import "./components/floater/floater.js";
import "./components/header/header.js";
import "./components/hero/hero.js";
import "./components/skiplinks/skiplinks.js";
import "./components/topic/topic.js";
import "./components/video/video.js";

import "./js/browser-detect";
import "./js/lazyload";
//import "./js/check-visible";

//import AOS from "aos";

//AOS.init();

const floaterMover = document.getElementById('floater')

function moveFloater(e) {
	TweenLite.to(floaterMover, 0.3, {
    css: {
      left: e.pageX,
      top: e.pageY
    }
  });
}

window.addEventListener("mousemove", (event) => {
	moveFloater(event);
});

//$(window).on('mousemove', moveCircle);
